<template>
    <div>
        <div class="preloader" v-if="loading">
        <div class="circular-spinner">
          <img src="../assets/images/shiba-loading.png" width="200">
        </div>
        </div>
    <div class="container">
        <div class="row">
            <div class="col-md-5 mx-auto">
                <div id="first">
                    <div class="myform form ">
                        <div class="logo mb-3">
                            <div class="col-md-12 text-center">
                                <img src="../assets/images/logo-shiba888.png"
                                    style="max-width: 100%;margin-bottom: 15px;">
                                <div v-if="pictureUrl">
                                  <img :src="pictureUrl" width="60" style="border-radius: 50%;">
                                </div>
                                <h2 v-if="step===1.2" style="color:#FFBB00">ยืนยันรหัส OTP</h2>
                                <h2 v-if="step!==4">สมัครสมาชิก</h2>
                                <template v-else>
                                    <h3 class="text-center text-white" style="font-size:30px;color: #fec33f !important;">Register Success</h3>
                                     <h3 class="text-center text-white" style="font-size:20px;font-weight: 500;color: #fec33f !important;">การสมัครของคุณสำเร็จแล้ว</h3>
                                </template>
                            </div>
                        </div>
                        <form id="msform" name="msform" method="post" onsubmit="return false;">
                        <div class="x-step-register" v-if="step!=4">
                            <div class="px-0 m-auto -container-wrapper">
                                <div :class="{ '-step-box-outer': true, 'step-active': step==1  || step==1.2 }"></div>
                                <div :class="{ '-step-box-outer': true, 'step-active': step==2 }"></div>
                                <div :class="{ '-step-box-outer': true, 'step-active': step==3 }"></div>
                            </div>
                        </div>
                        <fieldset v-if="step==1">
                            <p class="mt-1 text-white small" style="text-align: center;">
                                กรุณากรอกเบอร์โทรศัพท์ของท่าน</p>
                            <div class="form-group">
                                <div class="input-group mb-4">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">
                                            <i class="fa fa-user"></i>
                                        </span>
                                    </div>
                                    <input type="number" v-model="mobile" id="mobile" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="10" class="form-control" placeholder="เบอร์โทรศัพท์" aria-describedby="basic-addon1">
                                </div>
                            </div>
                            <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                                <p class="text-center">
                                    <a class="btn btn-blu-grow" @click="checkPhone()">ต่อไป <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                                </p>
                            </div>
                            <!-- <div class="col-md-12 mb-3 text-center" style="padding-top:8px;padding-left:0px;padding-right:0px;" v-if="!userId">
                                <button type="button" class="google btn mybtn cCsozd ewHin" @click="lineLogin()"><img class="icon" src="../assets/images/icon-line.svg" alt="line">สมัครระบบด้วยไลน์</button>
                            </div> -->
                        </fieldset>
                        <fieldset v-if="step==1.2">
                          <p class="mt-1 text-white small" style="text-align: center;">รหัส OTP จะส่งไปที่เบอร์มือถือของท่าน
                          </p>
                          <p class="mt-1 text-white small" style="text-align: center;">เบอร์ {{mobileFormat()}}
                          </p>
                          <div class="form-group">
                              <input type="text" v-model="otp" class="form-control mb-3" placeholder="กรอกรหัส OTP จากเบอร์มือถือของท่าน"
                                  id="otp" name="otp" style="text-align: center;" maxlength="4" >
                          </div>
                          <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                              <p class="text-center">
                                  <a class="btn btn-blu-grow" @click="checkOTP()">ยืนยัน OTP <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                              </p>
                          </div>
                          <p class="mt-1 text-white small" style="text-align: center;font-size: 12px;" @click="resendOTP()"><i :class="{'fas':true, 'fa-sync-alt':true, 'f-10':true, 'fa-spin':fa_spin}"></i>&nbsp;รับรหัส OTP ใหม่อีกครั้ง
                          </p>
                          <a href="javascript:;" @click="step=1" class="previous action-button-previous"><i
                                  class="fa fa-angle-left" aria-hidden="true" style="color: #eee;"></i>
                              ย้อนกลับ</a>
                      </fieldset>
                        <fieldset v-if="step==2">
                            <p class="mt-1 text-white small" style="text-align: center;">กรุณากรอกรหัสผ่านของท่าน
                            </p>
                            <div class="form-group">
                                <input type="password" v-model="password" class="form-control mb-3" placeholder="รหัสผ่าน"
                                    id="password" name="password">
                                <input type="password" v-model="password2" class="form-control" placeholder="ยืนยันรหัสผ่าน"
                                    id="con_password">
                            </div>
                            <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                                <p class="text-center">
                                    <a class="btn btn-blu-grow" @click="checkPassword()">ต่อไป <i class="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                                </p>
                            </div>
                            <a href="javascript:;" @click="step=1" class="previous action-button-previous"><i
                                    class="fa fa-angle-left" aria-hidden="true" style="color: #eee;"></i>
                                ย้อนกลับ</a>
                        </fieldset>
                        <fieldset v-if="step==3">
                            <p class="mt-1 text-white small" style="text-align: center;">กรุณาเลือกธนาคารของท่าน</p>
                            <div class="form-group">
                                <select v-model="bank" class="form-control mb-3">
                                    <option value="0">เลือกธนาคาร</option>
                                    <option value="truewallet">Truewallet</option>
                                    <option value="bankokbank">BBL - ธนาคารกรุงเทพ</option>
                                    <option value="kbank">KBANK - ธนาคารกสิกรไทย</option>
                                    <option value="scb">SCB - ธนาคารไทยพาณิชย์</option>
                                    <option value="tisco">TISCO - ธนาคารทิสโก้</option>
                                    <option value="ttb">TTB - ทหารไทยธนชาต</option>
                                    <option value="ktb">KTB - ธนาคารกรุงไทย</option>
                                    <option value="krungsri">BAY - ธนาคารกรุงศรีอยุธยา</option>
                                    <option value="kiatnakin">KKP - ธนาคารเกียรตินาคิน</option>
                                    <option value="gsb">GSB - ธนาคารออมสิน</option>
                                    <option value="lh">LHBANK - ธนาคารแลนด์ แอนด์ เฮ้าส์</option>
                                    <option value="uob">UOB - ธนาคารยูโอบี</option>
                                    <option value="ธนาคารอิสลาม">IBANK - ธนาคารอิสลามแห่งประเทศไทย</option>
                                    <option value="ghbank">GHB - ธนาคารอาคารสงเคราะห์</option>
                                    <option value="cimb">CIMB - ธนาคารซีไอเอ็มบีไทย</option>
                                    <option value="baac">BAAC - ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (ธกส)</option>
                                    <option value="icbc">ICBC - ธนาคารไอซีบีซี (ไทย)</option>
                                    <option value="standard">STANDARD - ธนาคารสแตนดาร์ดชาร์เตอร์ดไทย</option>
                                </select>

                                <input type="number" class="form-control mb-3" placeholder="เลขที่บัญชี" v-model="account_no" @blur="checkBankAccount()">
                                <input type="text" class="form-control mb-3" placeholder="ชื่อ" v-model="firstname">
                                <input type="text" class="form-control " placeholder="นามสกุล" v-model="lastname">
                                <!-- <div>
                                    <label for="f-option" class="l-radio">
                                        <input type="radio" id="f-option" name="prostatus" value="Y" tabindex="1">
                                        <span>รับโบนัส</span>
                                    </label>
                                    <label for="s-option" class="l-radio">
                                        <input type="radio" id="s-option" name="prostatus" value="N" tabindex="2"
                                            checked="checked">
                                        <span>ไม่รับโบนัส</span>
                                    </label>
                                </div> -->

                            </div>
                            <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                                <p class="text-center">
                                    <a class="btn btn-blu-grow" @click="register()">สมัครสมาชิก <i class="fa fa-user-circle" aria-hidden="true"></i></a>
                                </p>
                            </div>
                            <a href="javascript:;"  @click="step=2" class="previous action-button-previous"><i
                                    class="fa fa-angle-left" aria-hidden="true" style="color: #eee;"></i>
                                ย้อนกลับ</a>
                        </fieldset>
                        <fieldset v-if="step==4">
                            <p class="mt-1 text-white small" style="text-align: center;">ขอบคุณสำหรับการสมัคร คุณสามารถใช้ชื่อเข้าระบบ และ รหัสผ่าน ด้่านล่างที่ตั้งไว้เพื่อเข้าสู่ระบบ</p>
                            <div class="bg_register_user text-left">
                                <h4 style="color: #000 !important;font-size:20px;">ชื่อเข้าระบบ: <span id="txt_username">{{mobile}}</span></h4>
                                <h4 style="color: #000 !important;font-size:20px;">รหัสผ่าน: <span id="txt_password">{{password}}</span></h4>
                            </div>
                             <div class="col-md-12 mb-3" style="padding-left:0px;padding-right:0px;">
                                <p class="text-center">
                                    <a class="btn btn-blu-grow" @click="gotoLogin()">เข้าสู่ระบบ <i class="fa fa-sign-in" aria-hidden="true"></i></a>
                                </p>
                            </div>
                        </fieldset>
                        </form>
                        <template v-if="step!=4">
                            <div class="col-md-12 ">
                                <div class="login-or">
                                    <hr class="hr-or">
                                    <span class="span-or">หรือ</span>
                                </div>
                            </div>

                            <div class="col-md-12 mb-3">
                                <div class="text-center" style="color: #FFF;">
                                    มีบัญชีอยู่แล้ว <router-link to="/login" style="color: #fec33f;text-decoration: underline !important;">เข้าสู่ระบบ</router-link>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
	  </div>
  </div>
</template>

<script>

import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
const axios = require('axios').default
const qs = require('qs')
export default {
  name: 'Login',
  async created () {
    const user = localStorage.getItem('userData')
    if (user) {
      const _user = JSON.parse(user)
      if (_user && _user.key && firebase.auth().currentUser) {
        router.replace('/')
      }
    }
    const { code, prefix, ref } = queryString.parse(window.location.search.replace('?', ''))
    if (prefix) {
      localStorage.setItem('prefix', prefix)
    }
    if (ref) {
      localStorage.setItem('ref', ref)
    }
    if (!code) return
    const result = await this.getToken(code)
    if (result && result.access_token) {
      const { data } = await this.getProfile(result.access_token)
      this.checkLineLogin(data)
    }
  },
  data () {
    return {
      otp: '',
    fa_spin: false,
      mobile: '',
      step: 1,
      password: '',
      password2: '',
      bank: 0,
      firstname: '',
      lastname: '',
      account_no: '',
      loading: false,
      userFriendId: '',
      clientId: '1656935750',
      callbackUri: 'https://duck1688.com/register',
      clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
      pictureUrl: '',
      userId: ''
    }
  },
  mounted () {
    // this.getSetting();
    const userData = localStorage.getItem('userId')
    if (userData) {
      try {
        const user = JSON.parse(userData)
        this.userId = user.userId
        this.pictureUrl = user.pictureUrl
      } catch (error) {

      }
    }
  },
  methods: {
    resendOTP () {
    this.fa_spin = true
    this.checkPhone()
    setTimeout(() => {
      this.fa_spin = false
    }, 3000)
  },
  async checkOTP () {
    const otp = (await firebase.database().ref(`/otpsms/${this.mobile}/value`).once('value')).val()
    if (!this.otp) {
      this.$swal({ icon: 'error', title: 'OTP!', text: 'โปรดระบุ OTP ที่ได้รับ!!!' })
      return
    }
    if (otp) {
      if (this.otp == otp) {
        this.step = 2
      } else {
        this.$swal({ icon: 'error', title: 'OTP!', text: 'OTP ไม่ถูกต้องโปรดตรวจสอบ OTP ใหม่!!!' })
      }
    } else {
      this.$swal({ icon: 'error', title: 'OTP!', text: 'OTP ไม่มีในระบบโปรดติดต่อแอดมิน @imi356!!!' })
    }
  },
  mobileFormat () {
    if (this.mobile) {
      return `0XX-XXXX-${this.mobile.toString().slice(-3)}`
    }
  },
    lineLogin () {
      const url = 'https://access.line.me/oauth2/v2.1/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid profile'
      }
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: 'consent',
          bot_prompt: 'aggressive'
        })
      }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
    checkLineLogin (data) {
      this.loading = true
      axios({
        method: 'post',
        url: 'https://api.duck1688.com/api/loginline',
        data: {
          userId: data.userId,
          pictureUrl: data.pictureUrl
        }
      }).then(async res => {
        if (res && res.data && res.data.status) {
          firebase.auth().signInWithEmailAndPassword(res.data.mobile.trim().toLowerCase() + '@duck1688.com', res.data.password.trim()).then(async (res) => {
            if (res.user) {
              router.replace('/')
            } else {
              this.error = 'โปรดติดต่อแอดมิน @duck1688'
            }
            this.loading = false
          }).catch(() => {
            this.$swal({ icon: 'error', title: 'ข้อความ!', text: 'เบอร์โทรศัพท์ / รหัสผ่าน ไม่ถูกต้องค่ะ!!!' })
            this.loading = false
          })
        } else {
          localStorage.setItem('userId', JSON.stringify(data))
          this.userId = data.userId
          this.pictureUrl = data.pictureUrl
          this.loading = false
        }
      })
    },
    async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = 'โปรดติดต่อแอดมิน'
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
    async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
    async checkFriend (token) {
      const { data } = await axios.get('https://api.line.me/friendship/v1/status', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      return data.friendFlag
    },
    register () {
      if (this.bank === 0) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณาเลือกธนาคารค่ะ!!!' })
      } else if (this.account_no.trim() === '') {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกหมายเลขบัญชีค่ะ!!!' })
      } else if (this.firstname.trim() === '') {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกชื่อค่ะ!!!' })
      } else if (this.lastname.trim() === '') {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกนามสกุลค่ะ!!!' })
      } else {
        this.loading = true

        axios({
          method: 'post',
          url: 'https://api.duck1688.com/api/createuser',
          data: {
            firstname: this.firstname.toString().trim(),
            lastname: this.lastname.toString().trim(),
            bank: this.bank,
            account_no: this.account_no,
            tel: this.mobile,
            password: this.password,
            userFriendId: (localStorage.getItem('ref')) ? localStorage.getItem('ref') : '',
            agent: (localStorage.getItem('prefix')) ? localStorage.getItem('prefix') : '',
            userId: this.userId,
            pictureUrl: this.pictureUrl
          }
        }).then(async response => {
          this.loading = false
          if (!response.data.status) {
            this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดติดต่อแอดมินที่ @duck1688 เพื่อแจ้งปัญหาในการสมัคร' })
          } else {
            this.step = 4
            localStorage.removeItem('userId')
            localStorage.removeItem('prefix')
            localStorage.removeItem('ref')
          }
        })
        this.step = 4
      }
    },
    checkBankAccount () {
      axios({
        method: 'get',
        url: 'https://api.duck1688.com/api/checkaccount/' + this.account_no.trim()
      }).then(async response => {
        if (!response.data.status) {
          this.$swal({ icon: 'error', title: 'Error!', text: 'หมายเลขบัญชีนี้เป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @duck1688' })
          this.account_no = ''
        }
      })
    },
    gotoLogin () {
      router.replace('/login')
    },
    checkPassword () {
      if (this.password.toString().trim().length < 6) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกรหัสผ่านอย่างน้อย 6 ตัวอักษรค่ะ!!!' })
      } else if (this.password !== this.password2) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'รหัสผ่านไม่ตรงกันค่ะ!!!' })
      } else {
        this.step = 3
      }
    },
    async checkPhone () {
      if (!this.mobile.toString().trim()) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'กรุณากรอกเบอร์โทรศัพท์ค่ะ!!!' })
      } else if (this.mobile.toString().trim().length !== 10) {
        this.$swal({ icon: 'error', title: 'Error!', text: 'เบอร์โทร จำนวนตัวอักษรต้อง 10 ตัวเท่านั้นค่ะ!!!' })
      } else {
        axios({
          method: 'get',
          url: 'https://api.duck1688.com/api/checkmobile/' + this.mobile.trim()
        }).then(async response => {
          if (!response.data.status) {
            this.$swal({ icon: 'error', title: 'Error!', text: 'หมายเลขโทรศัพท์นี้เป็นสมาชิกอยู่แล้วค่ะ หากมีปัญหาโปรดติดต่อ @duck1688' })
          } else {
            const sms = (await firebase.database().ref('/sms').once('value')).val()
            if (sms && sms.status) {
              this.step = 1.2
            } else {
              this.step = 2
            }
          }
        })
      }
    }
  }
}
</script>
<style scoped>
.x-step-register {
    margin: 0rem 0 2.2rem;
}
.m-auto {
    margin: auto!important;
}
.x-step-register .-container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    background: linear-gradient(
90deg
,#fe8a02,#ffe867);
    overflow: hidden;
}
.x-step-register .-container-wrapper .-step-box-outer.step-active {
    background-color: transparent;
}
.x-step-register .-container-wrapper .-step-box-outer {
    display: flex;
    justify-content: flex-end;
    width: 40px;
    height: 8px;
    background-color: #d2d2d2;
}
.x-step-register .-container-wrapper .-step-box-outer:not(:last-of-type):after {
    content: "";
    display: block;
    width: 2px;
    height: 8px;
    background-color: #6C0D0E;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}
</style>
